import { ProcessStatus } from "../statics/constants/importOperation/processStatus";

export class ProcessResult {
  constructor() {
    /** @type {Array<{rowIndex: number, columnIndex: number, status: string, description: string}>}*/
    this.resultProcessList = [];
    this._numberOfErrors = 0;
    this._numberOfWarnings = 0;
  }

  get numberOfErrors() {
    this._numberOfErrors = this.resultProcessList.filter((item) => item.status === ProcessStatus.ERROR || item.status === ProcessStatus.BLOCKING_ERROR).length;
    return this._numberOfErrors;
  }

  get numberOfWarnings() {
    this._numberOfWarnings = this.resultProcessList.filter((item) => item.status === ProcessStatus.WARNING).length;
    return this._numberOfWarnings;
  }

  /**
   * Ajoute un résultat de traitement à la liste des résultats.
   *
   * @param {Object} result - L'objet représentant le résultat à ajouter.
   * @param {number} result.rowIndex - L'index de la ligne concernée par le résultat.
   * @param {number} result.columnIndex - L'index de la colonne concernée par le résultat. Utiliser -1 pour un résultat concernant toute la ligne.
   * @param {ProcessStatus} result.status - Le statut du résultat (ex: "error", "warning", "blockingerror", "ok").
   * @param {string} result.description - La description détaillée du résultat.
   *
   * @example
   * // Ajouter une erreur pour une cellule spécifique
   * processResult.addResult({
   *   rowIndex: 2,
   *   columnIndex: 3,
   *   status: "error",
   *   description: "Valeur invalide dans la cellule"
   * });
   *
   * // Ajouter un avertissement pour une ligne entière
   * processResult.addResult({
   *   rowIndex: 5,
   *   columnIndex: -1,
   *   status: "warning",
   *   description: "Ligne potentiellement problématique"
   * });
   */
  addResult(result) {
    this.resultProcessList.push(result);
  }

  removeResultsForRow(rowIndex) {
    this.resultProcessList = this.resultProcessList.filter((item) => String(item.rowIndex) !== String(rowIndex));
  }

  clear() {
    this.resultProcessList = [];
    this._numberOfErrors = 0;
    this._numberOfWarnings = 0;
  }
}
