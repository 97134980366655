<template>
  <b-card text-variant="opalean-gray-dark" class="card-custom gutter-b w-100 h-100">
    <b-card-text class="flex-column align-items-center h-100">
      <!-- begin:: Table of operations -->
      <div class="table-container" style="overflow: auto; height: 42vh">
        <table class="table-custom table-striped table-hover">
          <thead>
            <tr>
              <!-- Placeholder for row number column -->
              <th scope="col" style="width: 1%; padding: 8px; color: transparent">0</th>
              <!-- Dynamic table headers -->
              <th v-for="(header, index) in headers" :key="index" :style="getHeaderClass(index)" style="padding: 8px" :id="'header-' + index">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="!noDataFound">
              <!-- Display rows if there are data -->
              <tr v-for="(row, rowIndex) in rows" :key="rowIndex" :style="getRowClass(rowIndex, -1)" ref="bodyRows">
                <th scope="row" style="padding: 8px; text-align: center">{{ rowIndex + 1 }}</th>
                <td v-for="(cell, columnIndex) in row" :key="columnIndex" :style="getCellClass(rowIndex, columnIndex)">
                  <textarea
                    v-if="columnIndex !== columnIndices[AllowedColumns.TYPE]"
                    v-model="rows[rowIndex][columnIndex]"
                    @input="updateCell(rowIndex, columnIndex)"
                    style="padding: 8px; text-align: center; resize: none; word-break: break-word"
                  ></textarea>
                  <div v-else style="padding: 8px; text-align: center; resize: none; cursor: default">{{ rows[rowIndex][columnIndex] }}</div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="100%" class="text-center" style="color: dimgrey; font-style: italic; background-color: white; height: 38vh" v-translate>
                  No data found
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-card-text>
    <!-- begin:: Card footer -->
    <template v-slot:footer>
      <div class="d-flex align-items-stretch w-100">
        <!-- begin:: List of errors and warnings -->
        <div class="flex-grow-1 d-flex flex-column">
          <div class="d-flex flex-row align-items-start">
            <!-- #region Display icons and number of errors -->
            <button class="d-flex align-items-center justify-content-center error-button" :class="{ active: isActiveError }" @click="toggleError">
              <b-icon-exclamation-circle-fill class="text-danger h3 m-2"></b-icon-exclamation-circle-fill>
              <span class="font-size-h6 text-center mr-2"> {{ numberOfErrors }} <translate>Errors</translate></span>
            </button>
            <!-- #endregion -->
            <!-- #region Display separator -->
            <div class="separator-custom mx-4"></div>
            <!-- #endregion -->
            <!-- #region Display icons and number of warnings -->
            <button class="d-flex align-items-center justify-content-center warning-button" :class="{ active: isActiveWarning }" @click="toggleWarning">
              <b-icon-exclamation-triangle-fill class="text-warning h3 m-2"></b-icon-exclamation-triangle-fill>
              <span class="font-size-h6 text-center mr-2">{{ numberOfWarnings }} <translate>Warnings</translate></span>
            </button>
            <!-- #endregion -->
            <div class="ml-auto d-flex flex-row align-items-center">
              <!-- #region Display cancel/return button -->
              <button
                v-if="showCancelOrReturnButton"
                class="d-flex align-items-center justify-content-center action-button"
                :class="cancelButtonProperties.class"
                @click="cancelUpload"
                style="width: 125px"
              >
                <b-icon :icon="cancelButtonProperties.icon" class="h3 m-2" :style="cancelButtonProperties.style"></b-icon>
                <span class="font-size-h6 text-center mr-2" v-translate>{{ cancelButtonProperties.text }}</span>
              </button>
              <!-- #endregion -->
              <div class="ml-4"></div>
              <!-- #region Display refresh/import button -->
              <button
                v-if="showUploadButton"
                class="d-flex align-items-center justify-content-center action-button"
                @click="uploadData()"
                :class="uploadButtonProperties.class"
                style="width: 125px"
                :key="refreshImportButtonKey"
              >
                <b-icon :icon="uploadButtonProperties.icon" class="h3 m-2" :style="uploadButtonProperties.style"></b-icon>
                <span class="font-size-h6 text-center mr-2" v-translate>{{ uploadButtonProperties.text }}</span>
              </button>
              <!-- #endregion -->
            </div>
          </div>
          <div class="mb-4"></div>
          <!-- #region Table of errors and warnings -->
          <div class="table-container" style="overflow: auto; height: 14vh">
            <table class="table-custom table-striped table-hover">
              <thead>
                <tr>
                  <!-- Error status icon column -->
                  <th scope="col" style="width: 1%; padding: 8px; text-align: center">
                    <b-icon-info-circle-fill class="text-muted"></b-icon-info-circle-fill>
                  </th>
                  <!-- New Error Type column -->
                  <th scope="col" style="width: 15%; padding: 8px"><translate>Error Type</translate></th>
                  <!-- Description column -->
                  <th scope="col" style="padding: 8px"><translate>Description</translate></th>
                  <!-- Line number column -->
                  <th scope="col" style="width: 10%; padding: 8px"><translate>Line</translate></th>
                  <!-- Column name column -->
                  <th scope="col" style="width: 10%; padding: 8px"><translate>Column</translate></th>
                </tr>
              </thead>
              <tbody>
                <template v-if="processResult.numberOfErrors > 0 || processResult.numberOfWarnings > 0">
                  <!-- Table of errors and warnings -->
                  <tr v-for="(error, index) in filteredErrorsAndWarnings" :key="index">
                    <!-- Display error/warning icon based on status -->
                    <td style="padding: 8px; text-align: center">
                      <b-icon-exclamation-circle-fill
                        v-if="error.status === 'error' || error.status === 'blockingerror'"
                        class="text-danger"
                      ></b-icon-exclamation-circle-fill>
                      <b-icon-exclamation-triangle-fill v-else class="text-warning"></b-icon-exclamation-triangle-fill>
                    </td>
                    <!-- New Error Type column -->
                    <td style="padding: 8px">
                      <span v-if="error.status === 'error'" v-translate>Correctable Error</span>
                      <span v-else-if="error.status === 'blockingerror'" v-translate>Blocking Error</span>
                      <span v-else v-translate>Warning</span>
                    </td>
                    <!-- Description column -->
                    <td style="padding: 8px">{{ error.description }}</td>
                    <!-- Line number column -->
                    <td style="padding: 8px">{{ error.rowIndex + 1 }}</td>
                    <!-- Column name column -->
                    <td style="padding: 8px">{{ headers[error.columnIndex] }}</td>
                  </tr>
                </template>
                <template v-else>
                  <!-- No errors or warnings found message -->
                  <tr>
                    <td colspan="100%" class="text-center" style="color: dimgrey; font-style: italic; background-color: white; height: 10vh" v-translate>
                      No errors or warnings found
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <!-- #endregion -->
        </div>
      </div>
    </template>
    <!-- end:: Card footer -->
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import { AllowedColumns, mandatoryColumns, partnerColumns } from "@/core/statics/constants/importOperation/fileColumns";
import { Roles } from "@/core/statics/constants/importOperation/roles";
import { Flows } from "@/core/statics/constants/importOperation/operationType";
import { ProcessStatus } from "@/core/statics/constants/importOperation/processStatus";
import { ImportStatus } from "@/core/statics/constants/importOperation/importStatus";
import { ProcessResult } from "@/core/classes/ProcessResult";

export default {
  data() {
    return {
      AllowedColumns: AllowedColumns,
      ProcessStatus: ProcessStatus,
      Roles: Roles,
      Type: Flows,
      isCorrectFile: false,
      isActiveError: false,
      isActiveWarning: false,
      /**
       * Array of column headers from the CSV file.
       * @type {string[]}
       * @description
       * Contains the values of proprieties on AllowedColumnsInFile.
       * Used to identify specific columns in the data rows.
       */
      headers: [],
      /**
       * Two-dimensional array of processed CSV data.
       * @type {string[][]}
       * @description
       * Each inner array represents a row of data from the CSV file (excluding the header).
       * Contains values corresponding to the properties defined in AllowedColumnsInFile.
       * Empty rows are filtered out, and date values are formatted to "DD/MM/YYYY".
       * Access data with rows[rowIndex][columnIndex].
       *
       * Example:
       * For CSV content "DateOp,TypeOp,RefOp\n2023-01-01,A,123",
       * rows will be [["01/01/2023", "A", "123"]].
       */
      rows: [],
      requiredColumns: [],
      operationType: "",
      isBlockingError: false,
      apiReturn: false,
      showCancelButton: false,
      showReturnButton: false,
      refreshImportButtonKey: 0,
      columnIndices: {},
      palletIndices: [],
      processResult: new ProcessResult(),
    };
  },
  props: {
    selectedFile: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getPartners", "getPallets", "getUser"]),
    /**
     * Filters errors and warnings according to active parameters.
     * @returns {Array} Filtered list of errors and/or warnings.
     */
    filteredErrorsAndWarnings() {
      if (!this.isActiveError && !this.isActiveWarning) {
        return [];
      }
      return this.processResult.resultProcessList.filter((error) => {
        if (this.isActiveError && (error.status === ProcessStatus.ERROR || error.status === ProcessStatus.BLOCKING_ERROR)) {
          return true;
        }
        if (this.isActiveWarning && error.status === ProcessStatus.WARNING) {
          return true;
        }
        return false;
      });
    },

    /**
     * Checks if the list of lines is empty.
     * @returns {boolean} True if no rows exist, false otherwise.
     */
    noDataFound() {
      return this.rows.length === 0;
    },

    /**
     * Determines whether to show the cancel or return button.
     * @return {boolean} - True if either cancel or return button should be shown.
     */
    showCancelOrReturnButton() {
      return this.showCancelButton || this.showReturnButton;
    },

    /**
     * Provides the properties for the cancel or return button.
     * @return {Object} - Object containing class, icon, style, and text for the button.
     */
    cancelButtonProperties() {
      return {
        class: this.showCancelButton ? "action-button-cancel" : "action-button-return",
        icon: this.showCancelButton ? "x-circle-fill" : "arrow-left-circle-fill",
        style: this.showCancelButton ? "color: #dc3545" : "color: #6c757d",
        text: this.showCancelButton ? "Cancel" : "Return",
      };
    },

    /**
     * Determines whether to show the refresh or import button.
     * @return {boolean} - True if either refresh or import button should be shown.
     */
    showUploadButton() {
      return !this.noDataFound && !this.isBlockingError && this.isCorrectFile;
    },

    /**
     * Provides the properties for the refresh or import button.
     * @return {Object} - Object containing class, icon, style, and text for the button.
     */
    uploadButtonProperties() {
      return {
        class: "action-button-upload",
        icon: "cloud-upload",
        style: "color: #28a745",
        text: "Upload",
      };
    },

    numberOfErrors() {
      return this.processResult.numberOfErrors;
    },

    numberOfWarnings() {
      return this.processResult.numberOfWarnings;
    },
  },
  methods: {
    toggleError() {
      this.isActiveError = !this.isActiveError;
    },

    toggleWarning() {
      this.isActiveWarning = !this.isActiveWarning;
    },

    forceRefreshImportButtonUpdate() {
      this.refreshImportButtonKey++;
    },

    /**
     * Resets all states and data related to the file upload.
     */
    cancelUpload() {
      this.isCorrectFile = false;
      this.isActiveError = false;
      this.isActiveWarning = false;
      this.headers = [];
      this.rows = [];
      this.requiredColumns = [];
      this.operationType = "";
      this.$emit("fileDropped", false);
      this.forceRefreshImportButtonUpdate();
    },

    /**
     * Converts CSV content into structured data with headers and rows.
     * @param {string} csvContent - The CSV content as a string.
     */
    parseCSVContent(csvContent) {
      const lines = csvContent.split("\n");
      const [headerLine, ...dataLines] = lines;
      this.headers = headerLine.split(",");
      const dateIndex = this.headers.indexOf(AllowedColumns.Date);
      this.rows = dataLines
        .map((line) => line.split(","))
        .filter((row) => row.some((cell) => cell.trim()))
        .map((row) => {
          if (dateIndex !== -1 && row[dateIndex]) {
            row[dateIndex] = this.formatDate(row[dateIndex], "DD/MM/YYYY");
          }
          return row;
        });
      this.processRowsAndColumns();
    },

    /**
     * Gets the status of a header column.
     * @param {number} columnIndex - The index of the column.
     * @returns {Object|undefined} - The status of the header or undefined if not found.
     */
    getHeader(columnIndex) {
      return this.processResult.resultProcessList.find((result) => result.rowIndex === -1 && result.columnIndex === columnIndex);
    },

    /**
     * Gets the CSS class for a header column based on its status.
     * @param {number} columnIndex - The index of the column.
     * @returns {string} - The CSS class for the header.
     */
    getHeaderClass(columnIndex) {
      const header = this.getHeader(columnIndex);
      if (!header) return "";
      switch (header.status) {
        case ProcessStatus.ERROR:
        case ProcessStatus.BLOCKING_ERROR:
          return "background-color: #f8d7da; border: 2px solid #e74a3b;";
        case ProcessStatus.WARNING:
          return "background-color: #ffe8cc; border: 2px solid #ffa500;";
        default:
          return;
      }
    },

    /**
     * Retrieves the processing result for a specific cell.
     *
     * @param {number} rowIndex - The row index of the cell.
     * @param {number} columnIndex - The column index of the cell.
     * @returns {{
     *   rowIndex: number,
     *   columnIndex: number,
     *   status: ProcessStatus,
     *   description: string
     * } | undefined} The processing result for the specified cell or undefined if not found.
     *
     * @example
     * // Get the processing result for the cell at row 2, column 3
     * const cellResult = getCell(2, 3);
     * if (cellResult) {
     *   console.log(`Status: ${cellResult.status}, Description: ${cellResult.description}`);
     * } else {
     *   console.log('No processing result found for this cell');
     * }
     */
    getCell(rowIndex, columnIndex) {
      return this.processResult.resultProcessList.find((result) => result.rowIndex === rowIndex && result.columnIndex === columnIndex);
    },

    /**
     * Gets the CSS class for a row based on cell status.
     * @param {number} rowIndex - The index of the row.
     * @param {number} columnIndex - The index of the column.
     * @returns {string} - The CSS class for the row.
     */
    getRowClass(rowIndex, columnIndex) {
      const cellStatus = this.getCell(rowIndex, columnIndex);
      if (!cellStatus) return "";
      if (cellStatus.status === ProcessStatus.ERROR) return "background-color: #ffcccc;";
    },

    /**
     * Gets the CSS class for a cell based on its status.
     * @param {number} rowIndex - The index of the row.
     * @param {number} columnIndex - The index of the column.
     * @returns {string} - The CSS class for the cell.
     */
    getCellClass(rowIndex, columnIndex) {
      const currentCell = this.getCell(rowIndex, columnIndex);
      if (!currentCell) return "";
      if (currentCell.status === ProcessStatus.ERROR) {
        return "background-color: #f8d7da; border: 2px solid #e74a3b;";
      }
      if (currentCell.status === ProcessStatus.WARNING) {
        return "background-color: #ffe8cc; border: 2px solid #ffa500;";
      }
    },

    /**
     * Updates the value of a cell based on user input.
     * @param {Event} event - The input event containing the new value.
     * @param {number} rowIndex - The index of the row.
     * @param {number} columnIndex - The index of the column.
     */
    updateCell(rowIndex, columnIndex) {
      // Supprimer les résultats existants pour cette ligne
      this.processResult.removeResultsForRow(rowIndex);
      // Si on a une ligne dans les erreurs avec un rowIndex à -1, on retraite l'ensemble des lignes
      if (this.processResult.resultProcessList.some((error) => error.rowIndex === -1)) {
        this.processResult.removeResultsForRow(-1);
        this.processRowsAndColumns();
      } else {
        // Process only the modified line
        this.processCells([this.rows[rowIndex]], this.headers, rowIndex);
      }
      this.isCorrectFile = this.processResult.numberOfErrors === 0 && !this.isBlockingError;
      this.forceRefreshImportButtonUpdate();
    },

    /* Process Rows and Columns methods */
    /**
     * Processes rows and headers for validation and error handling.
     * @param {Array} rows - Array of row data.
     * @param {Array} headers - Array of column headers.
     */
    processRowsAndColumns() {
      this.processResult.clear();
      const headerErrors = this.processHeader(this.headers);
      if (headerErrors) {
        this.handleHeaderErrors(headerErrors);
      }
      this.checkUniqueRefOp(this.rows, this.headers);
      this.processCells(this.rows, this.headers);
      this.showCancelButton = !this.isBlockingError;
      this.showReturnButton = this.isBlockingError;
    },

    /**
     * Checks the uniqueness of values in the 'RefOp' column.
     *
     * This method goes through all rows of the imported file and verifies
     * that each value in the 'RefOp' column is unique. If duplicates
     * are found, errors are added to this.dataProcessing.
     *
     * @param {Array<Array<string>>} rows - The data rows from the imported file.
     * @param {Array<string>} headers - The column headers of the file.
     * @throws {Error} Implicitly via this.dataProcessing if duplicates are found.
     * @affects {this.dataProcessing} - Adds errors if duplicates are found.
     * @affects {this.isBlockingError} - Set to true if errors are found.
     * @returns {void}
     */
    checkUniqueRefOp(rows, headers) {
      const refUniqueByTypeOp = {
        [Flows.LOADING]: new Set(),
        [Flows.TRANSFER]: new Set(),
        [Flows.UNLOADING]: new Set(),
      };
      const refOpIndex = headers.indexOf(AllowedColumns.REF_OP);
      if (refOpIndex === -1) return;
      rows.forEach((row, rowIndex) => {
        const refOp = row[refOpIndex];
        if (!refOp) return;
        const operationType = row[this.columnIndices[AllowedColumns.TYPE]];
        let isRefOpAndTypeOpNotUnique;
        let isRefOpAlreadyInTransfert = refUniqueByTypeOp.T.has(refOp);

        switch (operationType) {
          case Flows.LOADING:
            isRefOpAndTypeOpNotUnique = refUniqueByTypeOp.C.has(refOp);
            if (isRefOpAndTypeOpNotUnique) {
              this.addProcessingError(
                rowIndex,
                refOpIndex,
                ProcessStatus.BLOCKING_ERROR,
                `${this.$gettext("Duplicate operation reference in loading")} ${refOp}.`
              );
              this.isBlockingError = true;
            } else if (isRefOpAlreadyInTransfert) {
              this.addProcessingError(
                rowIndex,
                refOpIndex,
                ProcessStatus.BLOCKING_ERROR,
                `${this.$gettext("Duplicate operation reference in transfert")} ${refOp}.`
              );
              this.isBlockingError = true;
            } else {
              refUniqueByTypeOp.C.add(refOp);
            }
            break;
          case Flows.UNLOADING:
            isRefOpAndTypeOpNotUnique = refUniqueByTypeOp.D.has(refOp);
            if (isRefOpAndTypeOpNotUnique) {
              this.addProcessingError(
                rowIndex,
                refOpIndex,
                ProcessStatus.BLOCKING_ERROR,
                `${this.$gettext("Duplicate operation reference in unloading")} ${refOp}.`
              );
              this.isBlockingError = true;
            } else if (isRefOpAlreadyInTransfert) {
              this.addProcessingError(
                rowIndex,
                refOpIndex,
                ProcessStatus.BLOCKING_ERROR,
                `${this.$gettext("Duplicate operation reference in transfert")} ${refOp}.`
              );
              this.isBlockingError = true;
            } else {
              refUniqueByTypeOp.D.add(refOp);
            }
            break;
          case Flows.TRANSFER:
            isRefOpAndTypeOpNotUnique = refUniqueByTypeOp.T.has(refOp);
            if (isRefOpAndTypeOpNotUnique) {
              this.addProcessingError(
                rowIndex,
                refOpIndex,
                ProcessStatus.BLOCKING_ERROR,
                `${this.$gettext("Duplicate operation reference in transfert")} ${refOp}.`
              );
              this.isBlockingError = true;
            } else if (refUniqueByTypeOp.D.has(refOp) || refUniqueByTypeOp.C.has(refOp)) {
              this.addProcessingError(
                rowIndex,
                refOpIndex,
                ProcessStatus.BLOCKING_ERROR,
                `${this.$gettext("Duplicate operation reference in transfert with loading or unloading")} ${refOp}.`
              );
              this.isBlockingError = true;
            } else {
              refUniqueByTypeOp.T.add(refOp);
            }
            break;
          default:
            break;
        }
      });
    },

    /**
     * Processes the header to validate columns and handle errors.
     * @param {Array} headers - Array of column headers.
     * @returns {Object|null} - Object containing header errors or null if no errors.
     */
    processHeader(headers) {
      const headerErrors = {};
      const columnIndices = {};
      let hasLoadedQuantityColumn = false;
      // Check pallet reference
      headers.forEach((header, index) => {
        if (this.isPalletReference(header)) {
          if (header.startsWith("+")) {
            hasLoadedQuantityColumn = true;
          }
          if (!this.isValidPalletReference(header, index)) {
            this.addProcessingError(
              -1,
              index,
              ProcessStatus.BLOCKING_ERROR,
              `${this.$gettext("The pallet reference")} "${header.substring(1)}" ${this.$gettext("does not exist")}.`
            );
            this.isBlockingError = true;
          }
        }
      });
      // Check that there is at least one pallet column starting with '+'
      if (!hasLoadedQuantityColumn) {
        headerErrors["loadedQuantity"] = {
          status: ProcessStatus.BLOCKING_ERROR,
          description: this.$gettext("At least one pallet column starting with '+' is required."),
        };
        this.isBlockingError = true;
      }
      // Check for mandatory columns
      mandatoryColumns.forEach((column) => {
        const index = headers.indexOf(column);
        if (index === -1) {
          headerErrors[column] = {
            status: ProcessStatus.BLOCKING_ERROR,
            description: `${this.$gettext("Mandatory column")} ${column} ${this.$gettext("is missing")}.`,
          };
          this.isBlockingError = true;
        } else {
          columnIndices[column] = index;
        }
      });
      // Store indices for all allowed columns
      Object.values(AllowedColumns).forEach((column) => {
        const index = headers.indexOf(column);
        if (index !== -1) {
          columnIndices[column] = index;
        }
      });
      // Check for invalid columns
      headers.forEach((header, index) => {
        if (!Object.values(AllowedColumns).includes(header) && !this.isPalletReference(header)) {
          headerErrors[header] = {
            status: ProcessStatus.ERROR,
            description: `${this.$gettext("Invalid column")} ${header}.`,
          };
        }
      });
      this.columnIndices = columnIndices;
      return Object.keys(headerErrors).length > 0 ? headerErrors : null;
    },

    /**
     * Handles header errors by adding them to the processing data.
     * @param {Object} errors - Object containing header errors.
     */
    handleHeaderErrors(errors) {
      for (const [index, { status, description }] of Object.entries(errors)) {
        this.addProcessingError(-1, +index, status, description);
      }
    },

    /**
     * Checks if a column header is a pallet reference (starts with "+" or "-").
     * @param {string} column - The column header to check.
     * @returns {boolean} - True if it is a pallet reference, otherwise false.
     */
    isPalletReference(column) {
      return column.startsWith("+") || column.startsWith("-");
    },

    /**
     * Checks if a pallet reference is valid by comparing it with known pallet references.
     * @param {string} column - The pallet reference to check.
     * @param {string} index - The index of the column pallet reference.
     * @returns {boolean} - True if the pallet reference is valid, otherwise false.
     */
    isValidPalletReference(column, index) {
      const palletRef = column.substring(1);
      const isValideRef = this.getPallets.some((p) => p.PalletRef === palletRef);
      if (isValideRef) {
        this.palletIndices.push(index);
      }
      return isValideRef;
    },

    /**
     * Processes cells in rows based on column headers and evaluates their validity.
     * @param {Array} rows - Array of row data.
     * @param {Array} headers - Array of column headers.
     */
    processCells(rows, headers, startRowIndex = 0) {
      const indices = this.getColumnIndices(headers);
      rows.forEach((row, index) => {
        const rowIndex = startRowIndex + index;
        let isMyPartnerRefPresent = false;
        let hasPalletMovement = false;
        // Single actor verification
        const uniqueActorsCheck = this.checkUniqueActors(row, indices);
        if (uniqueActorsCheck.status === ProcessStatus.ERROR) {
          this.addProcessingError(rowIndex, -1, uniqueActorsCheck.status, uniqueActorsCheck.description);
        }
        // Check that the carrier is present when a subcontractor is specified
        const carrierCheck = this.checkCarrierPresenceWithSubcontractor(row, indices);
        if (carrierCheck.status === ProcessStatus.ERROR) {
          this.addProcessingError(rowIndex, indices.REF_TPS, carrierCheck.status, carrierCheck.description);
        }
        headers.forEach((columnName, columnIndex) => {
          const cellValue = row[columnIndex].trim();
          const { status, description } = this.evaluateCell(columnName, cellValue, indices);
          this.addProcessingError(rowIndex, columnIndex, status, description);
          const myPartnerRef = this.getPartners.find((p) => p.PartnerID === this.getUser.ClientID)?.Reference;
          if (myPartnerRef && cellValue === myPartnerRef) {
            isMyPartnerRefPresent = true;
          }
          // Check if the column is a pallet column and the value is not zero
          if ((columnName.startsWith("+") || columnName.startsWith("-")) && cellValue !== "0" && cellValue !== "") {
            hasPalletMovement = true;
          }
        });

        if (!isMyPartnerRefPresent) {
          this.addProcessingError(rowIndex, -1, ProcessStatus.ERROR, `${this.$gettext("Your partner reference is not present in the line")}.`);
        }
        // Add error if no pallet movement is detected in the row
        if (!hasPalletMovement) {
          this.addProcessingError(
            rowIndex,
            -1,
            ProcessStatus.ERROR,
            this.$gettext("At least one pallet quantity (in '+' or '-' columns) must be different from 0.")
          );
        }
      });
      // Vérifier les colonnes requises seulement si on traite toutes les lignes
      if (startRowIndex === 0) {
        const missingColumns = this.requiredColumns.filter((column) => !headers.includes(column));
        if (missingColumns.length > 0) {
          missingColumns.forEach((column) => {
            this.addProcessingError(
              -1,
              -1,
              ProcessStatus.ERROR,
              `${this.$gettext("Required column")} ${column} ${this.$gettext("is missing or there is a wrong ")} ${AllowedColumns.TYPE}.`
            );
          });
        }
      }
    },

    /**
     * Checks that each actor reference appears only once per row.
     * @param {string[]} row - The data row to check.
     * @param {Object} indices - The indices of columns for each actor type.
     * @returns {Object} - An object containing the status and error description, if any.
     */
    checkUniqueActors(row, indices) {
      const actorRefs = new Set();
      const actorRefsColumns = ["REF_SHIP", "REF_TPS", "REF_ST", "REF_REC"];
      for (const column of actorRefsColumns) {
        const ref = row[indices[column]];
        if (ref && actorRefs.has(ref)) {
          return {
            status: ProcessStatus.ERROR,
            description: `${this.$gettext("The actor reference")} ${ref} ${this.$gettext("appears more than once in the line.")}`,
          };
        }
        if (ref) {
          actorRefs.add(ref);
        }
      }
      return { status: ProcessStatus.OK, description: "" };
    },

    /**
     * Checks if a carrier reference is present when a subcontractor is specified.
     * @param {string[]} row - The data row to check.
     * @param {Object} indices - The indices of columns for each actor type.
     * @returns {Object} - An object containing the status and error description, if any.
     */
    checkCarrierPresenceWithSubcontractor(row, indices) {
      const subcontractorIndex = this.headers.findIndex((h) => h === "SubcontractorRef");
      if (subcontractorIndex !== -1 && row[subcontractorIndex] && !row[indices.REF_TPS]) {
        return {
          status: ProcessStatus.ERROR,
          description: this.$gettext("Carrier is mandatory when subcontractor is specified"),
        };
      }
      return { status: ProcessStatus.OK, description: "" };
    },

    /**
     * Maps allowed columns to their indices in the headers array.
     * @param {Array} headers - Array of column headers.
     * @returns {Object} - Object mapping column names to their indices.
     */
    getColumnIndices(headers) {
      const indices = {};
      Object.keys(AllowedColumns).forEach((key) => {
        const column = AllowedColumns[key];
        indices[key] = headers.indexOf(column);
      });
      return indices;
    },

    /**
     * Evaluates a cell value based on its column header and returns its status and description.
     * @param {string} columnName - The column header.
     * @param {string} cellValue - The value of the cell.
     * @param {Object} columnIndices - Object mapping column names to their indices.
     * @returns {Object} - Object containing status and description.
     */
    evaluateCell(columnName, cellValue, columnIndices) {
      if (columnName === AllowedColumns.TYPE) {
        this.setOperationType(cellValue);
      }
      switch (columnName) {
        case AllowedColumns.DATE:
          return this.evaluateDateCell(cellValue);
        case AllowedColumns.TYPE:
          return this.evaluateTypeCell(cellValue);
        case AllowedColumns.REF_OP:
          return this.evaluateRefOpCell(cellValue);
        case AllowedColumns.REF_SHIP:
        case AllowedColumns.REF_TPS:
        case AllowedColumns.REF_REC:
        case AllowedColumns.REF_ST:
          return this.evaluatePartnerCell(cellValue);
        case AllowedColumns.CMR:
        case AllowedColumns.SHIP:
        case AllowedColumns.TPS:
        case AllowedColumns.ST:
        case AllowedColumns.REC:
          return { status: ProcessStatus.OK, description: "" };
        default:
          return this.evaluatePalletCell(columnName, cellValue);
      }
    },

    /**
     * Sets the operation type and updates required columns based on it.
     * @param {string} cellValue - The value of the cell indicating the operation type.
     */
    setOperationType(cellValue) {
      const operationTypeColumns = {
        [Flows.LOADING]: [AllowedColumns.REF_SHIP, AllowedColumns.REF_TPS],
        [Flows.TRANSFER]: [AllowedColumns.REF_SHIP, AllowedColumns.REF_REC],
        [Flows.UNLOADING]: [AllowedColumns.REF_TPS, AllowedColumns.REF_REC],
      };
      if (cellValue) {
        const newRequiredColumns = operationTypeColumns[cellValue] || [];
        this.requiredColumns = [...new Set([...this.requiredColumns, ...newRequiredColumns])];
        this.operationType = cellValue;
      } else {
        this.operationType = "";
      }
    },

    /**
     * Evaluates the validity of a date cell "DD/MM/YYYY".
     * @param {string} cellValue - The value of the date cell.
     * @returns {Object} - Object containing status and description.
     */
    evaluateDateCell(cellValue) {
      const isValidDate = /^\d{2}\/\d{2}\/\d{4}$/.test(cellValue);
      if (!isValidDate) {
        return { status: ProcessStatus.ERROR, description: `${this.$gettext("Invalid date format. Please use DD/MM/YYYY")}.` };
      }
      if (this.isDateInFuture(cellValue)) {
        return { status: ProcessStatus.ERROR, description: `${this.$gettext("Date cannot be in the future")}.` };
      }
      return { status: ProcessStatus.OK, description: "" };
    },

    /**
     * Checks if the date is in the future compared to the current date.
     * @param {string} date - The date string to check.
     * @returns {boolean} - True if the date is in the future, otherwise false.
     */
    isDateInFuture(date) {
      const currentDate = new Date();
      const [day, month, year] = date.split("/");
      const cellDate = new Date(`${year}-${month}-${day}`);
      return cellDate > currentDate;
    },

    /**
     * Evaluates the operation ref op cell to ensure it is not empty.
     * @param {string} cellValue - The value of the date cell.
     * @returns {Object} - Object containing status and description.
     */
    evaluateRefOpCell(cellValue) {
      if (cellValue) {
        return { status: ProcessStatus.OK, description: "" };
      }
      return { status: ProcessStatus.ERROR, description: `${this.$gettext("The operation reference is required")}.` };
    },

    /**
     * Evaluates the operation type cell to ensure it is not empty or not in C,D or T.
     * @param {string} cellValue - The value of the date cell.
     * @returns {Object} - Object containing status and description.
     */
    evaluateTypeCell(cellValue) {
      switch (cellValue) {
        case Flows.LOADING:
        case Flows.TRANSFER:
        case Flows.UNLOADING:
          return { status: ProcessStatus.OK, description: "" };
        case "":
          return { status: ProcessStatus.ERROR, description: `${this.$gettext("The operation type is required")}.` };
        default:
          return { status: ProcessStatus.ERROR, description: `${this.$gettext("The operation must be C,T or D")}.` };
      }
    },

    /**
     * Evaluates partner reference cells for validity and required conditions.
     * @param {string} cellValue - The value of the cell.
     * @returns {Object} - Object containing status and description.
     */
    evaluatePartnerCell(cellValue) {
      if (cellValue && !this.getPartners.some((partner) => partner.Reference === cellValue)) {
        return { status: ProcessStatus.ERROR, description: `${this.$gettext("The partner")} ${cellValue} ${this.$gettext("does not exist")}.` };
      }
      return { status: ProcessStatus.OK, description: "" };
    },

    /**
     * Evaluates pallet cells for validity and ensures conditions are met.
     * @param {string} columnName - The column name of the pallet reference.
     * @param {string} cellValue - The value of the cell.
     * @returns {Object} - Object containing status and description.
     */
    evaluatePalletCell(columnName, cellValue) {
      if (!this.isPalletReference(columnName)) {
        return {
          status: ProcessStatus.BLOCKING_ERROR,
          description: `${this.$gettext(`the column ${columnName} pallet is not start with "+" or "-"`)}`,
        };
      }
      if (!cellValue) {
        return {
          status: ProcessStatus.ERROR,
          description: `${this.$gettext("The value is missing. The default value is 0")}.`,
        };
      }
      if (isNaN(Number(cellValue))) {
        return {
          status: ProcessStatus.ERROR,
          description: this.$gettext("The value must be a number."),
        };
      }
      if (columnName.startsWith("-") && this.operationType === "T" && cellValue > 0) {
        return {
          status: ProcessStatus.ERROR,
          description: `${this.$gettext("For transfer operations (T), pallets cannot be received. The value must be 0")}.`,
        };
      }
      return { status: ProcessStatus.OK, description: "" };
    },

    /**
     * Adds an error or warning to the processing data and checks the error threshold.
     * @param {number} rowIndex - Index of the row where the error occurred.
     * @param {number} columnIndex - Index of the column where the error occurred.
     * @param {string} status - Status of the error (e.g., Status.ERROR, Status.WARNING).
     * @param {string} description - Description of the error or warning.
     */
    addProcessingError(rowIndex, columnIndex, status, description) {
      if (!this.checkErrorThreshold()) {
        if (columnIndex === -1) {
          let insertIndex = this.processResult.resultProcessList.findIndex((error) => error.rowIndex === rowIndex && error.columnIndex !== -1);
          if (insertIndex === -1) {
            this.processResult.addResult({ rowIndex, columnIndex, status, description });
          } else {
            this.processResult.resultProcessList.splice(insertIndex, 0, { rowIndex, columnIndex, status, description });
          }
        } else {
          this.processResult.addResult({ rowIndex, columnIndex, status, description });
        }
      }
    },

    /**
     * Checks if the number of errors exceeds the allowed threshold.
     * @returns {boolean} - True if the error threshold is exceeded, otherwise false.
     */
    checkErrorThreshold() {
      const errorThreshold = 100;
      if (this.processResult.numberOfErrors > errorThreshold) {
        this.finishProcess(
          ImportStatus.ERROR,
          `${this.$gettext("The number of errors exceeded the threshold of")} ${errorThreshold} ${this.$gettext(
            "errors. The file processing has been canceled"
          )}.`
        );
        return true;
      }
      return false;
    },

    /**
     * Formats a date string or Date object into a specified output format.
     * @param {string | Date} date - Date string in format DD/MM/YYYY or Date object.
     * @param {string} outputFormat - Desired output format (default is "YYYY-MM-DDTHH:mm:ssZ").
     * @returns {string} - Formatted date string.
     */
    formatDate(date, outputFormat = "YYYY-MM-DDTHH:mm:ssZ") {
      const datePattern = /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/;
      const now = new Date();
      let day, month, year;
      if (typeof date === "string" && datePattern.test(date)) {
        const [, d, m, y] = date.match(datePattern);
        day = d.padStart(2, "0");
        month = m.padStart(2, "0");
        year = y.length === 2 ? `20${y}` : y;
      } else if (date instanceof Date) {
        day = String(date.getDate()).padStart(2, "0");
        month = String(date.getMonth() + 1).padStart(2, "0");
        year = String(date.getFullYear());
      } else {
        return date; // Return original date if it's neither string nor Date object.
      }
      const replacements = {
        DD: day,
        MM: month,
        YYYY: year,
        HH: String(now.getHours()).padStart(2, "0"),
        mm: String(now.getMinutes()).padStart(2, "0"),
        ss: String(now.getSeconds()).padStart(2, "0"),
        Z: `+${String(-now.getTimezoneOffset() / 60).padStart(2, "0")}:00`,
      };

      return outputFormat.replace(/DD|MM|YYYY|HH|mm|ss|Z/g, (match) => replacements[match] || match);
    },

    /**
     * Uploads data operations to the server.
     * @async
     */
    uploadData() {
      const operations = this.rows.map((row) => this.createOperation(row));
      const data = { Operations: operations };
      (async () => {
        try {
          await ApiService.post("/operation/import", data);
          this.finishProcess(ImportStatus.SUCCESS, this.$gettext("Operations imported successfully"));
        } catch (error) {
          this.handleUploadError(error);
        }
      })();
    },

    /**
     * Handles errors that occur during data upload.
     * @param {Error} error - The error object from the upload attempt.
     */
    handleUploadError(error) {
      if (error.response && error.response.status === 500) {
        return;
      }
      let errorMessage = this.$gettext("Failed to upload data:");
      if (error.response && error.response.data) {
        if (Array.isArray(error.response.data.Errors)) {
          error.response.data.Errors.forEach((err) => {
            errorMessage += `\n${err.OperationRef}: ${err.Error}`;
          });
        } else {
          errorMessage += ` ${error.response.data}.`;
        }
      } else {
        errorMessage += ` ${error.message}.`;
      }
      this.finishProcess(ImportStatus.ERROR, errorMessage);
    },

    /**
     * Creates an operation object from a row of data.
     * @param {string[]} row - Array of values representing a single operation.
     * @returns {Object} - The created operation object.
     */
    createOperation(row) {
      const myRole = this.getMyRole(row);
      const operationDate = this.formatDate(row[this.columnIndices[AllowedColumns.DATE]], "YYYY-MM-DDTHH:mm:ssZ");
      let operation = {
        OperationDate: operationDate,
        OperationID: 0,
        OperationType: row[this.columnIndices[AllowedColumns.TYPE]],
        OperationCMR: row[this.columnIndices[AllowedColumns.CMR]],
        OperationRef: row[this.columnIndices[AllowedColumns.REF_OP]],
        MyRole: myRole,
        POVs: [
          {
            Pallets: [],
            Role: myRole,
          },
        ],
        Partners: [],
        Creation: {
          DateTime: operationDate,
          PartnerID: this.getUser.ClientID,
          TimeStamp: new Date().getTime(),
          Name: this.getUser.ClientName,
        },
        Stream: {
          StreamID: "0",
          Operations: [],
        },
      };
      this.addPartnersToOperation(operation, row);
      this.addPalletsToOperation(operation, row);
      return operation;
    },

    /**
     * Adds partners to the operation based on row data.
     * @param {Object} operation - Operation object to update.
     * @param {string[]} row - Array of values representing a single operation.
     */
    addPartnersToOperation(operation, row) {
      partnerColumns.forEach(({ column, role }) => {
        const index = this.columnIndices[column];
        if (index !== undefined) {
          const partnerRef = row[index];
          if (partnerRef) {
            const partner = this.getPartners.find((p) => p.Reference === partnerRef);
            if (partner) {
              operation.Partners.push({
                PartnerID: partner.PartnerID,
                PartnerRole: role,
                PartnerName: partner.Name,
              });
            }
          }
        }
      });
    },

    /**
     * Adds pallets to the operation based on row data and headers.
     * @param {Object} operation - Operation object to update.
     * @param {string[]} row - Array of values representing a single operation.
     */
    addPalletsToOperation(operation, row) {
      this.palletIndices.forEach((indice) => {
        if (this.headers[indice].startsWith("-")) return;
        const palletRef = this.headers[indice].substring(1);
        if (!palletRef) return;
        const currentPalet = this.getPallets.find((p) => p.PalletRef === palletRef);
        if (!currentPalet) return;
        let pallet = {
          PalletID: currentPalet.PalletID,
          PalletName: currentPalet.PalletName,
          FP: "0",
          FR: "0",
        };
        if (this.headers[indice].startsWith("+")) {
          pallet.FP = `${row[indice] || 0}`;
          if (this.headers[indice + 1] && this.headers[indice + 1].substring(1) == palletRef && this.headers[indice + 1].startsWith("-")) {
            pallet.FR = `${row[indice + 1] || 0}`;
          }
        }
        if (pallet.FP !== "0" || pallet.FR !== "0") {
          operation.POVs[0].Pallets.push(pallet);
        }
      });
    },

    /**
     * Determines the role of the current user based on row data.
     * @param {string[]} row - Array of values representing a single operation.
     * @returns {string} - Role of the current user.
     */
    getMyRole(row) {
      if (!row) return undefined;
      const myPartnerRef = this.getPartners.find((p) => p.PartnerID === this.getUser.ClientID)?.Reference;
      if (!myPartnerRef) return undefined;
      const roleMap = [
        { column: AllowedColumns.REF_REC, role: this.Roles.RECEIVER },
        { column: AllowedColumns.REF_TPS, role: this.Roles.CARRIER },
        { column: AllowedColumns.REF_SHIP, role: this.Roles.SHIPPER },
        { column: AllowedColumns.REF_ST, role: this.Roles.CARRIER_SUB_CONTRACTOR },
      ];
      for (const { column, role } of roleMap) {
        const index = this.columnIndices[column];
        if (index !== undefined && row[index] === myPartnerRef) {
          return role;
        }
      }
      return undefined;
    },

    /**
     * Finalizes the process and displays a message.
     * @param {string} status - Status of the process (e.g., "success", "error").
     * @param {string} message - Message to display.
     */
    finishProcess(status, message) {
      this.cancelUpload();
      this.displayNotification(status, message);
    },

    /**
     * Shows a brief notification message.
     * @param {ImportStatus} status - Type of notification (SUCCESS or ERROR).
     * @param {string} message - Text to display.
     */
    displayNotification(status, message) {
      Swal.mixin({
        toast: true,
        icon: status,
        title: message,
        position: "top-end",
        showConfirmButton: false,
        timer: 300,
      }).fire();
    },
  },

  watch: {
    /**
     * Watches for changes in the selected file and triggers file parsing.
     * @param {File} newVal - New file selected.
     */
    selectedFile: {
      handler(newVal) {
        this.parseCSVContent(newVal);
      },
      immediate: true,
    },

    /**
     * Watches for changes in the number of errors and updates error status.
     * @param {number} newVal - New number of errors.
     */
    numberOfErrors(newVal) {
      this.isActiveError = newVal > 0;
      this.isCorrectFile = newVal === 0;
      this.forceRefreshImportButtonUpdate();
    },

    /**
     * Watches for changes in the number of warnings and updates warning status.
     * @param {number} newVal - New number of warnings.
     */
    numberOfWarnings(newVal) {
      this.isActiveWarning = newVal > 0;
    },
  },

  mounted() {
    this.isActiveError = this.numberOfErrors > 0;
    this.isActiveWarning = this.numberOfWarnings > 0;
    this.isCorrectFile = this.numberOfErrors === 0;
    this.forceRefreshImportButtonUpdate;
  },
};
</script>

<style>
.table-container {
  border: solid 1px whitesmoke;
}
.table-custom {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.table-custom thead {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}
.table-custom th {
  font-weight: 600;
  color: hsl(13, 2%, 55%);
}
td[contenteditable="true"] {
  outline: none;
}
.error-button,
.warning-button,
.action-button {
  background: none;
  border: 2px solid transparent;
  padding: 5px;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-button:hover,
.warning-button:hover {
  border: 2px solid #ccc;
  background-color: rgba(0, 0, 0, 0.1);
}
.error-button.active {
  border: 2px solid #e74a3b;
}
.warning-button.active {
  border: 2px solid #ffa500;
}
.action-button-upload:hover {
  border: 2px solid #28a745;
  background-color: rgba(40, 167, 69, 0.1);
}
.action-button-refresh:hover {
  border: 2px solid #007bff;
  background-color: rgba(0, 123, 255, 0.1);
}
.action-button-cancel:hover {
  border: 2px solid #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
}
.separator-custom {
  width: 2px;
  height: 100%;
  background-color: #ccc;
}
</style>
