export const Roles = Object.freeze({
  SHIPPER: "MC",
  CARRIER: "MT",
  DOCK: "AD",
  RECEIVER: "MD",
  NOTIFIED: "NN",
  SHIPPER_SUB_CONTRACTOR: "SC",
  CARRIER_SUB_CONTRACTOR: "ST",
  RECEIVER_SUB_CONTRACTOR: "SD",
});

export const UserRoles = Object.freeze({
  4: Roles.SHIPPER,
  6: Roles.CARRIER,
  8: Roles.CARRIER_SUB_CONTRACTOR,
  10: Roles.RECEIVER,
});
