import { Roles } from "./roles";

export const AllowedColumns = Object.freeze({
  DATE: "DateOp",
  TYPE: "TypeOp",
  REF_OP: "RefOp",
  CMR: "CMR",
  REF_SHIP: "ShipperRef",
  SHIP: "ShipperName",
  REF_TPS: "CarrierRef",
  TPS: "CarrierName",
  REF_ST: "SubcontractorRef",
  ST: "SubcontractorName",
  REF_REC: "ReceiverRef",
  REC: "ReceiverName",
});

export const mandatoryColumns = [AllowedColumns.DATE, AllowedColumns.TYPE, AllowedColumns.REF_OP, AllowedColumns.CMR];

export const partnerColumns = [
  { column: AllowedColumns.REF_SHIP, role: Roles.SHIPPER },
  { column: AllowedColumns.REF_TPS, role: Roles.CARRIER },
  { column: AllowedColumns.REF_ST, role: Roles.CARRIER_SUB_CONTRACTOR },
  { column: AllowedColumns.REF_REC, role: Roles.RECEIVER },
];
